<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Groups
      </h5>
      <!-- <b-badge variant="primary">
        Add
      </b-badge> -->
      <small class="text-muted w-100 mb-1">{{ groups.length }} Groups</small>
    </b-card-header>

    <b-card-body class="mb-0">
      <div v-if="groups.length > 0" class="list-unstyled">
        <span  v-for="group in groups" :key="group._id">
          <b-badge variant="light-primary" style="padding: 9px" class=" mb-1 mr-1">
            {{ group.group }}
          </b-badge>
        </span>
        <!-- <li>
          <span class="align-middle">5 Users</span>
        </li>
        <li class="my-25">
          <span class="align-middle">10 GB storage</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
        </li> -->
      </div>
      <div v-else>
        <i>Belongs to no Group</i>
      </div>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Upgrade Plan
      </b-button> -->
    </b-card-body>

    <b-card-header class="d-flex justify-content-between align-items-center pt-0 pb-25">
      <h5 class="mb-0">
      Church Appointments
      </h5>
      <!-- <b-badge variant="primary">
        Add
      </b-badge> -->
      <small class="text-muted w-100 mb-1">{{ churchAppointments.length }} Appointments</small>
    </b-card-header>
    <b-card-body class="mb-0">
      <div v-if="churchAppointments.length > 0" class="list-unstyled">
        <span  v-for="appoint in churchAppointments" :key="appoint._id">
          <b-badge variant="light-primary" style="padding: 9px" class=" mb-1 mr-1">
            {{ appoint.appointment }}
          </b-badge>
          {{ appoint.from ? new Date(appoint.from).toDateString().substring(4)+" - "+new Date(appoint.to).toDateString().substring(4) : '' }}
        </span>
      </div>
      <div v-else>
        <i>Has no Appointment</i>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    groups: {
      type: Array
    },
    churchAppointments: {
      type: Array,
      default: [ ]
    },
    groupAppointments: {
      type: Array,
      default: []
    }
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
}
</script>

<style>

</style>
