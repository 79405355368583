<template>
  <b-card>
    
    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveMemberRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }}
                
              </h4>
              <b-badge
                  pill
                  :variant="`light-${resolveMemberStatusVariant(userData.membershipType)}`"
                  class="text-capitalize"
                >
                  {{ userData.membershipType }}
                </b-badge><br>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-members-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-primary"
                class="ml-1"
                :to="{ name: 'accounts-receipt-list', params: {  search: userData.fullName } }"
              >
                RECORDS
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <span>Membership ID :</span>
            <!-- <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                833
              </h5>
              <small>Monthly </small>
            </div> -->
          </div>

          <div class="d-flex align-items-center">
            <!-- <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                10,000
              </h5>
              <small>Annual </small>
            </div> -->

            <h3>#{{ userData.memID }}</h3>
            
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="8"
      >
      <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Personal Info</span>
        </template>
        <div class=" pt-75">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <!-- <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                /> -->
                <span class="font-weight-bold">Gender</span>
              </th>
              <td class="pb-50">
                {{ userData.gender.substring(0,1).toUpperCase()+userData.gender.substring(1) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <!-- <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                /> -->
                <span class="font-weight-bold">Contact</span>
              </th>
              <td class="pb-50">
                <!-- {{ userData.contact }} -->
                <span class="card-text"><a :href="`tel:${userData.contact1.countryCode+userData.contact1.tel}`">{{ userData.contact1.countryCode+userData.contact1.tel }}</a> </span>

                <span v-if="userData.contact2" class="card-text ">, <a class="ml-1" :href="`tel:${userData.contact2.countryCode+userData.contact2.tel}`">{{ userData.contact2.countryCode+userData.contact2.tel }}</a> </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <!-- <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                /> -->
                <span class="font-weight-bold">DOB, Dayborn, POB</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ new Date(userData.dob).toGMTString().substring(5,16) }}, <span class="ml-1"> {{ userData.dayborn }}</span>, <span class="ml-1"> {{ userData.pob }}</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <!-- <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                /> -->
                <span class="font-weight-bold">Nationality</span>
              </th>
              <td class="pb-50">
                {{ userData.nationality.substring(0,1).toUpperCase()+userData.nationality.substring(1) }}
              </td>
            </tr>
            <tr v-if="userData.languages">
              <th>
                <!-- <feather-icon
                  icon="MicIcon"
                  class="mr-75"
                /> -->
                <span class="font-weight-bold">Languages</span>
              </th>
              <td>
                {{ userData.languages.join(", ") }}
              </td>
            </tr>
            <tr v-if="userData.bio">
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Bio</span>
                </th>
                <td class="pb-50 ">
                  {{ userData.bio }}
                </td>
              </tr>
          </table>
        </div>
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Work, Address & Contacts </span>
          </template>
          <div class="pt-75">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="ShieldIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Work</span>
                </th>
                <td v-if="userData.workStatus" class="pb-50">
                  <span> {{ userData.workStatus.substring(0,1).toUpperCase()+userData.workStatus.substring(1) }}, </span>
                  <span class="ml-1" v-if="userData.workStatus !== 'unemployed'"> {{ userData.occupation }},</span>
                  <span class="ml-1" v-if="userData.workStatus !== 'unemployed'"> {{ userData.pow }}</span>
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="MapIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Address (Area Name)</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <a v-if="userData.address" :href="`https://www.google.com/maps/search/${userData.address[0]},${userData.address[1]}?entry=tts`" target="_blank"> {{ userData.addressName }} <span v-if="userData.residence">({{ userData.residence }})</span> </a>
                  
                  <div v-else>
                    {{ userData.addressName }} 
                    <span v-if="userData.residence">({{ userData.residence }})</span>
                  </div>
                  
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Hometown, Region, District</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <span> {{ userData.hometown }}, </span>
                  <span class="ml-1" v-if="userData.region"> {{ userData.region }},</span>
                  <span class="ml-1" v-if="userData.district"> {{ userData.district }}</span>
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="FlagIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Emergency Contact</span>
                </th>
                <td v-if="userData.emgCont" class="pb-50">
                  {{ userData.emgFname }}
                  ( {{ userData.emgRel }} )
                  <span class="card-text ml-1"><a :href="`tel:${userData.emgCont.countryCode+userData.emgCont.tel}`">{{ userData.emgCont.countryCode+userData.emgCont.tel }}</a> </span>
                </td>
              </tr>
              <tr>
                <th>
                  <!-- <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Next of Kin Contact</span>
                </th>
                <td v-if="userData.nokCont">
                  <!-- {{ userData.contact }} -->
                  {{ userData.nokFname }}
                  ( {{ userData.nokRel }} )
                  <span class="card-text ml-1"><a :href="`tel:${userData.nokCont.countryCode+userData.nokCont.tel}`">{{ userData.nokCont.countryCode+userData.nokCont.tel }}</a> </span>
                </td>
              </tr>
            </table>
          </div>
        </b-tab>

        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LinkIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Marital Info </span>
          </template>
          <div class="pt-75">
            <table class="mt-2 mt-xl-0 w-50">
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Marital Status</span>
                </th>
                <td v-if="userData.maritalStatus" class="pb-50">
                  {{ userData.maritalStatus.substring(0,1).toUpperCase()+userData.maritalStatus.substring(1) }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Name of Spouse</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.nameOfSpouse }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Date of Marriage</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.dateOfMarriage ? new Date(userData.dateOfMarriage).toGMTString().substring(5,16) : '' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="FlagIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Type of Marriage</span>
                </th>
                <td v-if="userData.typeOfMarriage" class="pb-50">
                  {{ userData.typeOfMarriage.substring(0,1).toUpperCase()+userData.typeOfMarriage.substring(1) }}
                </td>
              </tr>
              <tr>
                <th>
                  <!-- <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Marital Note</span>
                </th>
                <td>
                  {{ userData.maritalNote }}
                </td>
              </tr>
            </table>
          </div>
        </b-tab>

        <!-- Tab: Church Info -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="ShieldIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Church Info </span>
          </template>
          <div class="pt-75">
            <table class="mt-2 mt-xl-0 w-50">
              
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Date Joined</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.dateJoined ? new Date(userData.dateJoined).toGMTString().substring(5,16) : '' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Tithe Prayer</span>
                </th>
                <td v-if="userData.tithePayer" class="pb-50 text-capitalize">
                  {{ userData.tithePayer }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="FlagIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Confirmation Date</span>
                </th>
                <td class="pb-50">
                  {{ userData.confirmationDate ? new Date(userData.confirmationDate).toGMTString().substring(5,16) : '' }}
                </td>
              </tr>
              <tr>
                <th>
                  <!-- <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Baptism By</span>
                </th>
                <td>
                  {{ userData.baptismBy }}
                </td>
              </tr>
              <tr>
                <th>
                  <!-- <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Baptism Date</span>
                </th>
                <td>
                  {{ userData.baptismDate ? new Date(userData.baptismDate).toGMTString().substring(5,16) : '' }}
                </td>
              </tr>
              <tr v-if="userData.other">
                <th class="pb-50">
                  <!-- <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  /> -->
                  <span class="font-weight-bold">Other</span>
                </th>
                <td class="pb-50 ">
                  {{ userData.other }}
                </td>
              </tr>
            </table>
          </div>
        </b-tab>

        <!-- <b-tab>
          <template #title>
            <feather-icon
              icon="ShieldIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Other </span>
          </template>
          <div>

          </div>
        </b-tab> -->
      </b-tabs>
        
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BTab, BTabs
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useMembersList from '../members-list/useMemberList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BTab, BTabs
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveMemberRoleVariant, resolveMemberStatusVariant } = useMembersList()

    return {
      avatarText,
      resolveMemberRoleVariant,
      resolveMemberStatusVariant
    }
  },
}
</script>

<style>

</style>
