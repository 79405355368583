<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="memberData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching member data
      </h4>
      <div class="alert-body">
        No member found with this member id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-members-list'}"
        >
          Member List
        </b-link>
        for other members.
      </div>
    </b-alert>

    <template v-if="memberData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <member-view-member-info-card :user-data="memberData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <member-view-member-plan-card :groups="memberData.groups" :churchAppointments="memberData.churchAppointments"/>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <member-view-member-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <member-view-member-permissions-card />
        </b-col>
      </b-row> -->

      <!-- <invoice-list /> -->
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../memberStoreModule'
import MemberViewMemberInfoCard from './MemberViewMemberInfoCard.vue'
import MemberViewMemberPlanCard from './MemberViewMemberPlanCard.vue'
import MemberViewMemberTimelineCard from './MemberViewMemberTimelineCard.vue'
import MemberViewMemberPermissionsCard from './MemberViewMemberPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    MemberViewMemberInfoCard,
    MemberViewMemberPlanCard,
    MemberViewMemberTimelineCard,
    MemberViewMemberPermissionsCard,

    InvoiceList,
  },
  setup() {
    const memberData = ref(null)

    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'

    // Register module
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-member/fetchMember', { id: router.currentRoute.params.id })
      .then(() => { 
        
        // memberData.value = response.data 

        //
        const response =  store.getters['app-member/getMember']

        memberData.value = response
      
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 400) {
          memberData.value = {}
        }
      })

    return {
      memberData,
    }
  },
}
</script>

<style>

</style>
